import { useTranslation } from "react-i18next";
import SecondaryBtn from "./SecondaryBtn";
import pageLoaderAnimation from '../../../assets/images/loader/error.json';
import Lottie from "lottie-react";

const Error = () => {
    const { t } = useTranslation('common');

    return (
        <div className="w-full h-full flex flex-col items-center justify-center pb-[60px] xlx:pb-[70px]">
            <Lottie
                animationData={pageLoaderAnimation}
                loop={true}
                autoplay={true}
                className="w-[300px] h-[300px] my-[-30px]"
            />
            <h3 className="text-blackDull dark:text-textBlackDull font-bold text-center">{t("error.title")}</h3>
            <h6 className="text-blackDull dark:text-textBlackDull font-medium max-w-[575px] text-center mb-[20px] mt-[4px]">{t("error.desc")}</h6>
            <SecondaryBtn onClick={() => window.location.reload()} title={t("error.retry")} css="!rounded-[10px] px-[30px] xlx:px-[40px] font-extrabold uppercase !h-[35px]" />
        </div>
    );
};

export default Error;