/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { dataActions } from "../../../../shared/store/data-Slice";
import { ActivityLogo, Clock, CloseBlack, DrpArr, FileUpdIcon, GroupClose, LinkIcon, NoCommentsIcon, RemarkSend, SmileIcon, UplodedFile } from "../../../common/svg/AllSvg";
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import { url } from "../../../../shared/utils/url";
import useAxios from "../../../../shared/hooks/use-axios";
import { useTranslation } from "react-i18next";
import PageLoader from "../../../common/layout/PageLoader";
import moment from "moment";
// import SecondaryBtn from "../../../common/form/SecondaryBtn";
import Error from "../../../common/form/Error";
import SecondaryBtn from "../../../common/form/SecondaryBtn";
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { useDropzone } from "react-dropzone";
import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";
import { Bounce, toast, ToastContainer } from "react-toastify";
import { errors } from "../../../../shared/utils/errors";

const ComplaintDetail = ({ type }: any) => {
    const { t } = useTranslation('common');
    const complaintDetail: boolean = useSelector((state: any) => state.data.complaintDetail);
    const user: any = useSelector((state: any) => state.auth.user);
    const lang: string = useSelector((state: any) => state.ui.lang);
    const [complaintData, setComplaintData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [isError, setIsError] = useState<boolean>(false)
    const [stFocus, setStFocus] = useState<boolean>(false)
    const [select, setSelect] = useState<any>("activities");

    const [msg, setMsg] = useState<string>('');
    const textareaRef: any = useRef();
    const pickerRef: any = useRef(null);
    const [files, setFiles] = useState<any[]>([]);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [disable, setDisable] = useState<boolean>(false);
    const [selectFile, setSelectFile] = useState<any>("");
    const [showPicker, setShowPicker] = useState<boolean>(false);
    const [submit, setSubmit] = useState<boolean>(false);
    const [comments, setComments] = useState<any[]>([]);

    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const ref: any = useRef();
    const menus: any[] = [
        { name: t("vigilance.home.banner.status.pending_review"), key: "Pending_Review" },
        { name: t("vigilance.home.banner.status.under_investigation"), key: "Pending_Verification" },
        { name: t("vigilance.home.banner.status.resolved"), key: "Resolved" }
    ]

    const statusList: any = {
        "Pending_Review": t("vigilance.home.banner.status.pending_review"), "Pending_Verification": t("vigilance.home.banner.status.under_investigation"),
        // "Under_Investigation": t("vigilance.home.banner.status.action_taken"),
        "Resolved": t("vigilance.home.banner.status.resolved"), "Reopen": t("vigilance.home.banner.status.reopen")
    }

    const tabs: any[] = [
        { name: t("grievance.detail.act"), key: "activities" },
        { name: t("grievance.detail.comments"), key: "comments" },
    ]

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                dispatch(dataActions.setComplaintDetail(false));
                if (window?.location?.pathname?.includes("resolve")) {
                    !!params?.lang ? navigate(`/${params?.lang}/all-resolve-complaint`) : navigate(`/all-resolve-complaint`)
                } else {
                    type !== "passenger" ? (!!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)) : (!!params?.lang ? navigate(`/${params?.lang}/vigilance-complaints`) : navigate(`/vigilance-complaints`))
                }
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(dataActions.setComplaintDetail(false))
            if (window?.location?.pathname?.includes("resolve")) {
                !!params?.lang ? navigate(`/${params?.lang}/all-resolve-complaint`) : navigate(`/all-resolve-complaint`)
            } else {
                type !== "passenger" ? (!!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)) : (!!params?.lang ? navigate(`/${params?.lang}/vigilance-complaints`) : navigate(`/vigilance-complaints`))
            }
        }
    };

    const getComplaint = async () => {
        try {
            setLoading(true)
            setIsError(false)
            const res: any = await useAxios.get(`/${(!!lang ? lang : "en") + url?.vigilance_complaint_detail + params?.id}/`);

            if (res && res.status === 200 && res?.data?.length > 0) {
                setComplaintData(res?.data?.[0])
                setLoading(false)
            }
        } catch (error) {
            setIsError(true)
            setLoading(false)
            console.error("error while fetching complaint detail", error);
        }
    }

    const getComments = async () => {
        try {
            const res: any = await useAxios.get(`${url?.vigilance_ticket_comments}?ticket_id=${params?.id}`);

            if (res && res.status === 200) {
                setComments(res?.data)
            }
        } catch (error) {
            console.error("error while fetching ticket comments", error);
        }
    }

    useEffect(() => {
        (!!lang && !!params?.id) && getComplaint();
        (!!lang && !!params?.id) && getComments();
    }, [lang, params?.id])

    const handleStatusChange = async (key: any) => {
        try {
            if (key === "Resolved") {
                dispatch(dataActions.setComplaintDetail(false))
                !!params?.lang ? navigate(`/${params?.lang}/resolve-complaint/${params?.id}`) : navigate(`/resolve-complaint/${params?.id}`)
            } else {
                // if (user?.role === "Vigilance") {
                //     const res: any = await useAxios.put(`/${(!!lang ? lang : "en") + url?.vigilance_assign_complaint + complaintData?.id}/`, { status: key, activity_remarks: null });

                //     if (res && res.status === 200) {
                //         dispatch(dataActions.setComplaintApiCall(true));
                //         getComplaint()
                //     }
                // } else {
                dispatch(dataActions.setFeedback({ ...complaintData, status: key }))
                dispatch(dataActions.setAddRemarksComplaint(true))
                dispatch(dataActions.setComplaintDetail(false))
                // }
            }
        } catch (error) {
            console.error("error while status change", error);
        }
    }

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (pickerRef.current && !pickerRef.current.contains(event.target)) {
                setShowPicker(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [pickerRef]);

    useEffect(() => {
        const textarea: any = textareaRef.current;
        if (!!textarea) {
            if (textarea.scrollHeight < 117) {
                if (textarea.scrollHeight > 40) {
                    textarea.style.height = 'auto';
                    textarea.style.height = `${textarea.scrollHeight}px`;
                } else {
                    textarea.style.overflow = "hidden"
                    textarea.style.height = `22px`;
                }
                if (!!!msg) {
                    textarea.style.overflow = "hidden"
                    textarea.style.height = `22px`;
                }
            } else {
                if (!!msg) {
                    textarea.style.overflow = "auto"
                    textarea.style.height = `117px`;
                }

                if (!!!msg) {
                    textarea.style.overflow = "hidden"
                    textarea.style.height = `22px`;
                }
            }
        }
    }, [msg, select]);

    const handleEmojiSelect = (emoji: any) => {
        setMsg((prev) => prev + emoji.native);
        setShowPicker(false);
    };

    const simulateChunkUpload = (chunk: any) => {
        return new Promise((resolve: any, reject: any) => {
            setTimeout(() => {
                resolve();
            }, 1000);
        });
    };

    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop: (acceptedFiles: any) => {
            if (acceptedFiles[0].size > (5 * 1000 * 1024)) {
                toast.error(t("passenger.lost_found.report_form.validation.size_err"));
                return;
            }
            setUploadProgress(0);
            setDisable(true)
            setFiles([acceptedFiles[0]])

            let uploadFile = acceptedFiles[0];
            if (uploadFile) {
                const chunkSize = 1024 * 1024; // 1MB chunk size
                const totalChunks = Math.ceil(uploadFile.size / chunkSize);
                let currentChunk = 0;

                const uploadNextChunk = () => {
                    const start = currentChunk * chunkSize;
                    const end = Math.min(start + chunkSize, uploadFile.size);
                    const chunk = uploadFile.slice(start, end);

                    simulateChunkUpload(chunk)
                        .then(() => {
                            currentChunk++;

                            if (currentChunk < totalChunks) {
                                const progress = Math.floor((currentChunk / totalChunks) * 100);
                                setUploadProgress(progress);

                                uploadNextChunk();
                            } else {
                                setUploadProgress(100);
                                setDisable(false)
                            }
                        })
                        .catch((error) => {
                            console.error('Error uploading chunk', error);
                        });
                };

                uploadNextChunk();
            }
        }
    });

    const removeFile = (idx: number) => {
        let arr: any[] = [];
        for (let i = 0; i < files?.length; i++) {
            const el = files[i];

            if (idx !== i) {
                arr.push(el)
            }
        }

        setFiles(arr)
    };

    const thumbs = files.map((file: any, idx: number) => (
        <div className="flex flex-col relative" onMouseEnter={() => setSelectFile(idx)} onMouseLeave={() => setSelectFile("")} key={idx}>
            {selectFile === idx && <button aria-label={t("aria_label.delete")} title={t("aria_label.delete")} className="min-w-[20px] w-[20px] h-[20px] flex items-center justify-center rounded-full hover:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] focus:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] bg-white shadow-md border border-transparent focus:border-[#D7D8DD] hover:border-[#D7D8DD] absolute top-[-8px] right-[-8px]" onClick={() => removeFile(idx)} type="button">
                <GroupClose className="w-[8px] h-[8px]" />
            </button>}

            <Link to={URL.createObjectURL(file)} download={URL.createObjectURL(file)} target="_blank" className="rounded-[5px] border border-[#BEF7FF] bg-[#E5FFFD] px-[9px] py-[5px] gap-[6px] flex  font-semibold text-blackDull w-fit text-sm !leading-tight focus:shadow-[0_0_0_2px_#059db25e] hover:shadow-[0_0_0_2px_#059db25e] dark:bg-bgDark dark:border-[#777777] dark:text-textBlackDull break-all">
                <LinkIcon className="xlx:min-w-[10px] xlx:w-[10px] xlx:h-[16px] min-w-[9px] w-[9px] h-[15px] fill-blackDull dark:fill-textBlackDull" />
                {(uploadProgress !== 100 && (idx + 1 === files?.length)) ? t("passenger.lost_found.report_form.form.uploading") : file?.name}
            </Link>
        </div>
    ));

    const handleSubmit = async () => {
        try {
            setSubmit(true)
            const formData = new FormData();
            formData.append("comment", msg);
            formData.append("comment_type", 'Vigilance');
            formData.append("ticket", complaintData?.id);
            files?.length > 0 && formData.append("attachment", files?.[0]);
            const res: any = await useAxios.post(url?.vigilance_ticket_comments, formData);

            if (res && (res.status === 200 || res.status === 201)) {
                setMsg('');
                setFiles([]);
                setUploadProgress(0);
                setDisable(false);
                getComments();
            }
        } catch (error) {
            errors(error)
            setSubmit(false)
            console.error("error while create ticket comments", error);
        }
    }

    return (
        <>
            {complaintDetail &&
                <div className="fixed inset-0 z-[34] top-0 w-full h-full overflow-y-auto bg-[rgba(216,216,216,0.60)] backdrop-blur-[15px]">
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="m-[15px] mm:mx-auto w-full sm:w-[600px] md:w-[700px] msm:w-[930px] min-h-[550px] xlx:min-h-[583px] relative bg-white dark:bg-bgDarkDull rounded-[10px] shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12),0px_14px_64px_-4px_rgba(24,39,75,0.12)]" ref={ref}>
                            <ToastContainer
                                position="top-center"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                                transition={Bounce}
                            />

                            <div className="w-full flex justify-between gap-[15px] border-b border-[#DADADA] dark:border-[#454545] border-opacity-[0.5] px-[20px] xlx:px-[24px] py-[10px] xlx:py-[11px]">
                                <h5 className="text-primaryText dark:text-white font-semibold">{complaintData?.ticket_no}</h5>

                                <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="w-[25px] h-[25px] rounded-full focus:shadow-[0_0_0_2px_#4145525e] hover:shadow-[0_0_0_2px_#4145525e] dark:focus:shadow-[0_0_0_2px_#ffffff5e] dark:hover:shadow-[0_0_0_2px_#ffffff5e]" type="button" onClick={() => {
                                    dispatch(dataActions.setComplaintDetail(false))
                                    if (window?.location?.pathname?.includes("resolve")) {
                                        !!params?.lang ? navigate(`/${params?.lang}/all-resolve-complaint`) : navigate(`/all-resolve-complaint`)
                                    } else {
                                        type !== "passenger" ? (!!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)) : (!!params?.lang ? navigate(`/${params?.lang}/vigilance-complaints`) : navigate(`/vigilance-complaints`))
                                    }
                                }}>
                                    <CloseBlack />
                                </button>
                            </div>
                            {loading ?
                                <PageLoader />
                                : <>
                                    {isError ? <Error /> : <div className="h-full w-full py-[15px] xlx:py-[19px] px-[20px] xlx:px-[24px] flex gap-[15px] xlx:gap-[25px] flex-col xlsm:flex-row">
                                        <div className="w-full xlsm:w-[195px] bg-opacity-[0.2] bg-[#E4E4E4] rounded-[15px] border border-[#A7A7A7] h-full border-opacity-[0.2] flex flex-col pb-[130px] xlx:pb-[140px]">
                                            <h6 className="flex items-center justify-center w-full text-[#313131] dark:text-white font-semibold p-[15px] bg-[#D9D9D9] bg-opacity-[0.3] rounded-t-[15px]">{t("grievance.detail.general_info")}</h6>

                                            <div className="flex flex-col items-start gap-[4px] px-[20px] py-[12px] border-b border-[#DADADA] dark:border-[#454545] border-opacity-[0.5]">
                                                <p className="font-medium text-blackDull dark:text-textBlackDull">{t("grievance.detail.name")}</p>
                                                <h6 className="text-primaryText dark:text-white font-semibold break-word">{complaintData?.created_by_details?.first_name + " " + complaintData?.created_by_details?.last_name}</h6>
                                            </div>

                                            <div className="flex flex-col items-start gap-[4px] px-[20px] py-[12px] border-b border-[#DADADA] dark:border-[#454545] border-opacity-[0.5]">
                                                <p className="font-medium text-blackDull dark:text-textBlackDull">{t("grievance.detail.date")}</p>
                                                <h6 className="text-primaryText dark:text-white font-semibold break-word uppercase">{moment(complaintData?.created_at).format('DD MMM YYYY, hh:mm A')}</h6>
                                            </div>

                                            <div className="flex flex-col items-start gap-[4px] px-[20px] py-[12px] border-b border-[#DADADA] dark:border-[#454545] border-opacity-[0.5]">
                                                <p className="font-medium text-blackDull dark:text-textBlackDull">{t("grievance.detail.status")}</p>
                                                <p className="text-xs !leading-tight flex items-center justify-center whitespace-nowrap h-[22px] px-[11px] text-white font-semibold bg-[#FCAB02] border border-[#EEE] rounded-full">{statusList?.[complaintData?.status]}</p>
                                            </div>

                                            <div className="flex flex-col items-start gap-[4px] px-[20px] py-[12px] border-b border-[#DADADA] dark:border-[#454545] border-opacity-[0.5]">
                                                <p className="font-medium text-blackDull dark:text-textBlackDull">{t("auth.form.field.email")}</p>
                                                <h6 className="text-primaryText dark:text-white font-semibold break-all">{complaintData?.created_by_details?.email}</h6>
                                            </div>

                                            {!!complaintData?.created_by_details?.mobile && <div className="flex flex-col items-start gap-[4px] px-[20px] py-[12px] border-b border-[#DADADA] dark:border-[#454545] border-opacity-[0.5]">
                                                <p className="font-medium text-blackDull dark:text-textBlackDull">{t("grievance.detail.phone")}</p>
                                                <h6 className="text-primaryText dark:text-white font-semibold break-word">+91 {complaintData?.created_by_details?.mobile}</h6>
                                            </div>}
                                        </div>

                                        <div className="w-full xlsm:w-[calc(100%-210px)] xlx:w-[calc(100%-220px)] flex flex-col">
                                            <div className="flex items-center justify-between w-full gap-[15px] flex-wrap">
                                                <h6 className="text-blackDull dark:text-textBlackDull font-semibold">{t("grievance.detail.title")}</h6>
                                                {user?.role === "Vigilance" && <>
                                                    {complaintData?.status !== "Resolved" ?
                                                        <>
                                                            {(user?.role !== "passenger" && user?.role !== "candidate") && <div className={`flex xs:items-center w-full xs:w-fit toggle-submit-btn items-end justify-end rounded-[6px] ${stFocus && "shadow-[0_0_0_3px_#059db25e]"} hover:shadow-[0_0_0_3px_#059db25e]`}>
                                                                <div className="text-white text-sm !leading-snug font-bold h-[30px] w-fit bg-primary rounded-l-[6px] flex items-center justify-center whitespace-nowrap border-r border-white uppercase px-[12px]">{statusList?.[complaintData?.status]}</div>
                                                                <Menu align="end" menuButton={<MenuButton onFocus={() => setStFocus(true)} onBlur={() => setStFocus(false)}>
                                                                    <div className="h-[30px] min-w-[35px] flex items-center justify-center rounded-r-[6px] bg-primary">
                                                                        <DrpArr />
                                                                    </div>
                                                                </MenuButton>}>
                                                                    {Array.isArray(menus) && menus?.map((i: any, idx: number) => (
                                                                        <React.Fragment key={idx}>
                                                                            {i?.key !== complaintData?.status && <MenuItem onClick={() => handleStatusChange(i?.key)}><div className="text-blackDark text-sm !leading-snug font-semibold h-[30px] w-fit rounded-[5px] flex items-center justify-start px-[14px] whitespace-nowrap capitalize cursor-pointer">{i?.name}</div></MenuItem>}
                                                                        </React.Fragment>
                                                                    ))}
                                                                </Menu>
                                                            </div>}
                                                        </>
                                                        :
                                                        <SecondaryBtn title={t("grievance.home.banner.status.reopen")} css="!rounded-[6px] px-[20px] font-extrabold uppercase !h-[30px]" onClick={() => handleStatusChange("Reopen")} />
                                                    }
                                                </>}
                                            </div>

                                            <div className="mt-[8px] w-full rounded-[15px] bg-[#D9D9D9] bg-opacity-[0.2] p-[15px] xlx:p-[20px]">
                                                <div className="flex items-center gap-[12px]">
                                                    <div className="h-[30px] min-w-[30px] flex items-center justify-center rounded-full text-[#015293] text-[12px] font-semibold bg-[#F6FD8D] border border-[#42FD00] !leading-tight uppercase">
                                                        {complaintData?.created_by_details?.first_name?.charAt(0)}{complaintData?.created_by_details?.last_name?.charAt(0)}
                                                    </div>

                                                    <div className="flex flex-col">
                                                        <p className="text-xs leading-snug text-[#8795AB] dark:text-textBlackDull font-medium">{t("grievance.detail.from")}</p>
                                                        <h6 className="text-[#242555] dark:text-white font-semibold">{complaintData?.created_by_details?.first_name + " " + complaintData?.created_by_details?.last_name}</h6>
                                                    </div>
                                                </div>

                                                <h6 className="text-primaryText dark:text-white font-bold mb-[8px] mt-[12px] break-word">{complaintData?.subject}
                                                </h6>

                                                <h6 className="text-blackDull dark:text-textBlackDull font-medium break-word">{complaintData?.description}</h6>

                                                {!!complaintData?.attachment_details?.aws_file_url && <Link to={complaintData?.attachment_details?.aws_file_url} target="_blank" className="rounded-[5px] border border-[#BEF7FF] bg-[#E5FFFD] px-[9px] py-[5px] gap-[6px] flex  font-semibold text-blackDull w-fit mt-[17px] text-sm !leading-tight focus:shadow-[0_0_0_2px_#059db25e] hover:shadow-[0_0_0_2px_#059db25e] dark:bg-bgDark dark:border-[#777777] dark:text-textBlackDull break-all">
                                                    <LinkIcon className="xlx:min-w-[10px] xlx:w-[10px] xlx:h-[16px] min-w-[9px] w-[9px] h-[15px] fill-blackDull dark:fill-textBlackDull" />
                                                    {complaintData?.attachment_details?.name}.{complaintData?.attachment_details?.type}
                                                </Link>}
                                            </div>

                                            {(user?.role !== "passenger" && user?.role !== "candidate") && <div className="bg-[#F6F6F6] dark:bg-bgDark rounded-[10px] h-[40px] px-[4px] py-[2px] flex items-center gap-[5px] w-fit mt-[20px] mb-[12px]">
                                                {Array?.isArray(tabs) && tabs?.map((item: any, index: number) => (
                                                    <button key={index} className={`${item?.key === select ? "border border-[#DDE2E4] dark:border-[#454545] bg-white dark:bg-bgDarkDull focus:shadow-md text-primaryText dark:text-white" : "hover:shadow-md focus:shadow-md text-blackDull dark:text-textBlackDull"} whitespace-nowrap rounded-[10px] flex items-center justify-center px-[24px] xlx:px-[35px] font-bold text-sm h-full`} onClick={() => setSelect(item?.key)}>{item?.name}</button>
                                                ))}
                                            </div>}

                                            {select === 'activities' ? <>
                                                {complaintData?.ticket_activities?.length > 0 && <>
                                                    {(user?.role === "passenger" || user?.role === "candidate") && <h6 className="text-blackDull dark:text-textBlackDull font-semibold mt-[15px] mb-[12px]">{t("grievance.detail.act")}</h6>}

                                                    <div className="w-full rounded-[15px] bg-[#D9D9D9] bg-opacity-[0.2] p-[15px] xlx:p-[20px] flex flex-col gap-[15px] xlx:gap-[25px]">
                                                        {Array?.isArray(complaintData?.ticket_activities) && complaintData?.ticket_activities?.map((item: any, index: number) => (
                                                            <div className="flex gap-[12px]" key={index}>
                                                                {!!item?.created_by ? <div className="h-[20px] min-w-[20px] flex items-center justify-center rounded-full text-[#015293] text-[10px] font-semibold bg-[#F6FD8D] border border-[#42FD00] !leading-snug uppercase">
                                                                    {item?.created_by_details?.first_name?.charAt(0)}{item?.created_by_details?.last_name?.charAt(0)}
                                                                </div> :
                                                                    <ActivityLogo />
                                                                }

                                                                <div className="flex flex-col gap-[6px]">
                                                                    {/* create */}
                                                                    {item?.remark === "Ticket Created" && <h6 className="text-blackDull dark:text-textBlackDull font-normal">
                                                                        {lang === "en" ?
                                                                            <>
                                                                                <b className="capitalize">{item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name}  </b>
                                                                                Created Complaint to
                                                                                <b> {statusList?.[item?.ticket_status]}</b> section.
                                                                            </> :
                                                                            lang === "hi" ?
                                                                                <>
                                                                                    <b className="capitalize">{item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name}  </b>
                                                                                    ने शिकायत
                                                                                    <b> {statusList?.[item?.ticket_status]}</b> अनुभाग में बनाई।
                                                                                </> :
                                                                                <>
                                                                                    <b className="capitalize">{item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name}  </b>
                                                                                    यांनी तक्रार
                                                                                    <b> {statusList?.[item?.ticket_status]}</b> विभागात तयार केली.
                                                                                </>
                                                                        }


                                                                    </h6>}

                                                                    {/* assign to system */}
                                                                    {item?.remark === "Complaint Assigned by System" && <h6 className="text-blackDull dark:text-textBlackDull font-normal">{lang === "en" ? <>Complaint Assigned to <b>{item?.assigned_department_details?.name}</b>.</> : lang === "hi" ? <>शिकायत को <b>{item?.assigned_department_details?.name}</b> को सौंपा गया।</> : <>तक्रार <b>{item?.assigned_department_details?.name}</b> कडे सोपवली गेली आहे.</>} </h6>}

                                                                    {/* status change */}
                                                                    {((item?.remark === "Ticket Status Updated" && item?.ticket_status !== "Resolved") || (complaintData?.ticket_activities?.[index - 1]?.ticket_status !== item?.ticket_status && item?.ticket_status !== "Resolved" && item?.remark !== "Ticket Created" && item?.remark !== "Complaint Assigned by System")) &&
                                                                        <>
                                                                            <h6 className="text-blackDull dark:text-textBlackDull font-normal">
                                                                                {lang === "en" ?
                                                                                    <>
                                                                                        <b className="capitalize">{(item?.assigned_user === item?.created_by) ? item?.assigned_department_details?.name : item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name} </b>
                                                                                        Moved Complaint to
                                                                                        <b> {statusList?.[item?.ticket_status]}</b> section.
                                                                                    </> :
                                                                                    lang === "hi" ?
                                                                                        <>
                                                                                            <b className="capitalize">{(item?.assigned_user === item?.created_by) ? item?.assigned_department_details?.name : item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name} </b>
                                                                                            ने शिकायत को
                                                                                            <b> {statusList?.[item?.ticket_status]}</b> करने वाले अनुभाग में स्थानांतरित किया।
                                                                                        </> :
                                                                                        <>
                                                                                            <b className="capitalize">{(item?.assigned_user === item?.created_by) ? item?.assigned_department_details?.name : item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name} </b>
                                                                                            यांनी तक्रार
                                                                                            <b> {statusList?.[item?.ticket_status]}</b> विभागात हलवली.
                                                                                        </>
                                                                                }
                                                                            </h6>
                                                                            {(!!item?.remark && item?.remark !== "Ticket Status Updated") && <h6 className="text-blackDull dark:text-textBlackDull font-medium break-word">{item?.remark}</h6>}
                                                                        </>
                                                                    }

                                                                    {/* Resolved */}
                                                                    {item?.ticket_status === "Resolved" &&
                                                                        <>
                                                                            <h6 className="text-blackDull dark:text-textBlackDull font-normal"><b>{(item?.assigned_user === item?.created_by) ? item?.assigned_department_details?.name : item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name}</b> {lang === "en" ? "Marked Complaint Resolved." : lang === "hi" ? "ने शिकायत को समाधान किया हुआ चिह्नित किया।" : "यांनी तक्रार समाधानित म्हणून चिन्हांकित केली."}</h6>
                                                                            {!!item?.remark && <h6 className="text-blackDull dark:text-textBlackDull font-medium break-word">{item?.remark}</h6>}
                                                                        </>}

                                                                    {/* assign to user */}
                                                                    {(item?.ticket_status !== "Resolved" && item?.remark !== "Ticket Created" && item?.remark !== "Ticket Status Updated" && item?.remark !== "Complaint Assigned by System" && complaintData?.ticket_activities?.[index - 1]?.ticket_status === item?.ticket_status) &&
                                                                        <div className="flex flex-col gap-[6px]">
                                                                            <h6 className="text-blackDull dark:text-textBlackDull font-normal"> {lang === "en" ? <><b>{(item?.assigned_user === item?.created_by) ? item?.assigned_department_details?.name : item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name}</b> Complaint Assigned to <b>{!!item?.assigned_department_details ? item?.assigned_department_details?.name : ""}</b>.</> : lang === "hi" ? <><b>{(item?.assigned_user === item?.created_by) ? item?.assigned_department_details?.name : item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name}</b> ने शिकायत <b>{!!item?.assigned_department_details ? item?.assigned_department_details?.name : ""}</b> को सौंपी।</> : <><b>{(item?.assigned_user === item?.created_by) ? item?.assigned_department_details?.name : item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name}</b> यांनी तक्रार <b>{!!item?.assigned_department_details ? item?.assigned_department_details?.name : ""}</b> यांना सोपवली.</>}</h6>
                                                                            <h6 className="text-blackDull dark:text-textBlackDull font-medium">{item?.remark}</h6>
                                                                        </div>
                                                                    }

                                                                    {!!item?.attachment_details?.aws_file_url && <Link to={item?.attachment_details?.aws_file_url} target="_blank" className="rounded-[5px] border border-[#BEF7FF] bg-[#E5FFFD] px-[9px] py-[5px] gap-[6px] flex  font-semibold text-blackDull w-fit text-sm !leading-tight focus:shadow-[0_0_0_2px_#059db25e] hover:shadow-[0_0_0_2px_#059db25e] dark:bg-bgDark dark:border-[#777777] dark:text-textBlackDull break-all">
                                                                        <LinkIcon className="xlx:min-w-[10px] xlx:w-[10px] xlx:h-[16px] min-w-[9px] w-[9px] h-[15px] fill-blackDull dark:fill-textBlackDull" />
                                                                        {item?.attachment_details?.name}.{item?.attachment_details?.type}
                                                                    </Link>}

                                                                    <p className={`text-xs xlx:text-[0.688rem] leading-snug flex items-center gap-[6px] text-blackDull dark:text-textBlackDull font-semibold opacity-[0.7] ${item?.attachment_details?.aws_file_url ? "mt-[4px]" : ""}`}><Clock className="fill-blackDull dark:fill-textBlackDull" /> {moment(item?.created_at).format('DD MMM YYYY, hh:mm A')}</p>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>}
                                            </> :
                                                <div className="bg-[#D9D9D9] bg-opacity-[0.2] px-[16px] pt-[24px] pb-[10px] dark:bg-blackDark dark:bg-opacity-[0.2] rounded-[15px] w-full flex flex-col gap-[18px]">
                                                    {comments?.length > 0 ?
                                                        <>
                                                            {Array?.isArray(comments) && comments?.map((c: any, cIdx: number) => (
                                                                <div key={cIdx} className={`${c?.created_by?.email !== user?.email ? 'items-start justify-start' : 'items-end justify-end'} flex flex-col gap-[18px] w-full`}>
                                                                    {c?.created_by?.email !== user?.email ?
                                                                        <div>
                                                                            <div className="flex gap-[8px] w-full">
                                                                                <div className='h-[22px] min-w-[22px] flex items-center justify-center rounded-full text-[#015293] text-[10px] font-semibold bg-[#F6FD8D] border border-[#42FD00] !leading-snug uppercase mt-[1px]'>{c?.created_by?.first_name?.charAt(0) + c?.created_by?.last_name?.charAt(0)}</div>
                                                                                <div className="flex flex-col gap-[8px] w-full max-w-[490px]">
                                                                                    {!!c?.attachment?.aws_file_url && <Link to={c?.attachment?.aws_file_url} target="_blank" className="rounded-[5px] border border-[#BEF7FF] bg-[#E5FFFD] px-[9px] py-[5px] gap-[6px] flex  font-semibold text-blackDull w-fit text-sm !leading-tight focus:shadow-[0_0_0_2px_#059db25e] hover:shadow-[0_0_0_2px_#059db25e] dark:bg-bgDark dark:border-[#777777] dark:text-textBlackDull break-all">
                                                                                        <LinkIcon className="xlx:min-w-[10px] xlx:w-[10px] xlx:h-[16px] min-w-[9px] w-[9px] h-[15px] fill-blackDull dark:fill-textBlackDull" />
                                                                                        {c?.attachment?.name}.{c?.attachment?.type}
                                                                                    </Link>}
                                                                                    <div className="w-fit border border-[#EBEBEB] dark:border-[#454545] bg-[#F2F2F2] dark:bg-[#323232] px-[14px] py-[7px] rounded-[6px]">
                                                                                        <h6 className="text-blackDark dark:text-textBlackDark font-medium">{c?.comment}</h6>
                                                                                    </div>
                                                                                    <p className="text-xs xlx:text-[0.688rem] leading-snug flex items-center gap-[6px] text-blackDull dark:text-textBlackDull font-semibold opacity-[0.7]"><Clock className="fill-blackDull dark:fill-textBlackDull" /> {moment(c?.created_at).format('hh:mm A DD MMM YYYY')}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className="flex flex-col gap-[8px] w-full items-end justify-end max-w-[490px]">
                                                                            {!!c?.attachment?.aws_file_url && <Link to={c?.attachment?.aws_file_url} target="_blank" className="rounded-[5px] border border-[#BEF7FF] bg-[#E5FFFD] px-[9px] py-[5px] gap-[6px] flex  font-semibold text-blackDull w-fit text-sm !leading-tight focus:shadow-[0_0_0_2px_#059db25e] hover:shadow-[0_0_0_2px_#059db25e] dark:bg-bgDark dark:border-[#777777] dark:text-textBlackDull break-all">
                                                                                <LinkIcon className="xlx:min-w-[10px] xlx:w-[10px] xlx:h-[16px] min-w-[9px] w-[9px] h-[15px] fill-blackDull dark:fill-textBlackDull" />
                                                                                {c?.attachment?.name}.{c?.attachment?.type}
                                                                            </Link>}
                                                                            <div className="w-fit border border-[#EBEBEB] dark:border-[#454545] bg-[#ECF3FE] dark:bg-[#5a6372] px-[14px] py-[7px] rounded-[6px]">
                                                                                <h6 className="text-blackDark dark:text-textBlackDark font-medium">{c?.comment}</h6>
                                                                            </div>
                                                                            <p className="text-xs xlx:text-[0.688rem] leading-snug flex items-center gap-[6px] text-blackDull dark:text-textBlackDull font-semibold opacity-[0.7]"><Clock className="fill-blackDull dark:fill-textBlackDull" /> {moment(c?.created_at).format('hh:mm A DD MMM YYYY')}</p>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            ))}
                                                        </>
                                                        : <div className="h-[160px] flex items-center justify-center flex-col">
                                                            <NoCommentsIcon />
                                                            <h5 className="text-blackDull dark:text-textBlackDull font-medium pt-[6px]">{t("grievance.detail.no_comments")}</h5>
                                                        </div>}

                                                    <div className="w-full bg-white dark:bg-bgDarkDull border border-[rgba(130,124,170,0.20)] dark:border-[#454545] py-[10px] px-[14px] rounded-[10px] flex flex-col gap-[10px]">
                                                        {files?.length > 0 && <div className="flex gap-[15px] flex-wrap mb-[5px]">
                                                            {thumbs}
                                                        </div>}

                                                        <div className="flex items-start gap-[10px]">
                                                            <div className='h-[22px] min-w-[22px] flex items-center justify-center rounded-full text-[#015293] text-[10px] font-semibold bg-[#F6FD8D] border border-[#42FD00] !leading-snug uppercase'>{user?.first_name?.charAt(0)}{user?.last_name?.charAt(0)}</div>

                                                            <textarea ref={textareaRef} name="msg" id='msg' placeholder={t("grievance.detail.task_msg")} className="border-none outline-none focus:outline-none bg-transparent text-blackDull dark:text-textBlackDull text-sm font-semibold placeholder:text-sm placeholder:text-blackDull placeholder:text-opacity-[0.6] dark:placeholder:text-textBlackDull dark:placeholder:text-opacity-[0.6] placeholder:font-semibold flex items-center h-full w-full resize-none overflow-auto pt-[2px]" value={msg} onChange={(e: any) => setMsg(e.target.value)}></textarea>
                                                        </div>

                                                        <div className="flex items-center justify-between gap-[12px] w-full">
                                                            <div className="flex items-center gap-[4px]">
                                                                <button ref={pickerRef} className="min-w-[20px] h-[20px] w-[20px] flex items-center justify-center hover:bg-[#4e566d26] focus:bg-[#4e566d26] dark:hover:bg-[#d5d5d526] dark:focus:bg-[#d5d5d526] rounded-[4px] relative" onClick={() => setShowPicker(!showPicker)}>
                                                                    <SmileIcon />

                                                                    {showPicker && (
                                                                        <div className="absolute left-0 bottom-[30px]">
                                                                            <Picker data={data} onEmojiSelect={handleEmojiSelect} />
                                                                        </div>
                                                                    )}
                                                                </button>
                                                                <div {...getRootProps()} onClick={(e) => e.stopPropagation}>
                                                                    <input {...getInputProps()} />
                                                                    <button onClick={open} className="min-w-[20px] h-[20px] w-[20px] flex items-center justify-center hover:bg-[#4e566d26] focus:bg-[#4e566d26] dark:hover:bg-[#d5d5d526] dark:focus:bg-[#d5d5d526] rounded-[4px] disabled:bg-transparent disabled:cursor-not-allowed" disabled={disable || files?.length === 1}>
                                                                        <FileUpdIcon />
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <button className="min-w-[24px] h-[24px] w-[24px] flex items-center justify-center bg-primary rounded-full focus:shadow-[0_0_0_2px_#059db25e] hover:shadow-[0_0_0_2px_#059db25e] disabled:bg-[#D9D9D9] dark:disabled:bg-[#D9D9D926] disabled:cursor-not-allowed disabled:shadow-none" disabled={(!!msg && !disable && !submit) ? false : true} onClick={handleSubmit}>
                                                                <RemarkSend />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>}
                                </>}
                        </div>
                    </div>
                </div >}
        </>
    );
};

export default ComplaintDetail;