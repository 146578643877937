import Lottie from 'lottie-react';
import pageLoaderAnimation from '../../../assets/images/loader/page-loader.json';

const PageLoader = () => {
    return (
        <div className="w-full flex items-center justify-center py-[100px] xlx:py-[145px]">
            <Lottie
                animationData={pageLoaderAnimation}
                loop={true}
                autoplay={true}
                className="w-[200px] h-[200px]"
            />
        </div>
    );
};

export default PageLoader;